import './App.css';
import Hero from './Pages/Hero';
import Chat from './components/Chat';

function App() {
  return (
    <div className="bg-[#020305] h-full md:h-[100vh]  px-[2rem] py-[5rem]">
      <Hero/>
      <Chat/>
    </div>
  );
}

export default App;
