import React from "react";
import Button from "../components/Button";
import twelve from "../assets/12.png";
import thirteen from "../assets/13.png";
import apple from "../assets/Apple.png";
import play from "../assets/play.png";
const Hero = () => {
  return (
    <div className="flex flex-col md:flex-row item-center gap-[15rem] m-auto justify-center max-w-[100%]">
      <div className="max-w-[100%] w-[100%] md:max-w-[50%] md:w-[40%]">
        <h3 className="text-[#FFFFFF] font-[Urbanist1] text-[50px] md:text-[60px] leading-[76px] font-[600] tracking-wide">
          Find local <br/> government<br/> offices easily.
        </h3>
        <p className="text-[#FFFFFF] font-[Urbanist] text-[18px] mt-[1rem] leading-[30px] font-[400] tracking-normal">
          You can locate the nearest government office in just a few clicks,<br/>saving time and hassle.
        </p>
        <div className="flex justify-between w-[20rem] md:w-[23rem] mt-[2rem]">
          <div className='flex w-[9rem] md:w-[11rem] justify-center items-center bg-white/[0.1] gap-[1rem] font-Poppins drop-shadow-sm rounded h-[3rem] bg-[rgba(255, 255, 255, 0.1)]'>
          <img className="" src={apple} alt="apple" />
          <Button text={"Apple Store"}/>
          </div>
          <div className='flex w-[9rem] md:w-[11rem] justify-center bg-white/[0.1] items-center gap-[1rem] font-Poppins drop-shadow-sm rounded h-[3rem] bg-[red]'>
          <img className="" src={play} alt="play" />
          <Button text={"Google Play"}/>
          </div>
        </div>
      </div>
      <div className="flex max-w-[100vw] md:max-w-[50%] relative left-[0] md:left-0">
        <div className="relative w-[100%]">
        <img
          className=" w-[15rem] z-[1] relative right-[0] md:right-[7rem] md:bottom-[1rem]  h-[30rem]"
          src={thirteen}
          alt="iphone13"
        />
        <img
          className=" w-[15rem] absolute z-[1] h-[30rem] right-[1rem] md:right-[0] top-[3rem] "
          src={twelve}
          alt="iphone12"
        />
        </div>
      </div>
    </div>
  );
};

export default Hero;
