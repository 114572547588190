import React from 'react';

const Button = ({text}) => {
  return (
    <div className='flex gap-[1rem]'>
      <button className='font-Poppins font-[500] text-[16.7px] leading-[20px] text-[#FFFFFF]'> 
      {/* <img className='' src={logo} alt='iphone12'/> */}
{text} 
</button>
    </div>
  );
}

export default Button;
