import React from 'react';

const Chat = () => {


  return (
    <div >
        <df-messenger
  df-cx="true"
  location="us-central1"
  chat-title="Feranmi"
  agent-id="17f0e894-55e6-413d-b328-322713afb660"
  language-code="en"
></df-messenger>    
</div>
  );
}

export default Chat;
